<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-btn
            dark
            color="secondary"
            class="ma-2"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6 pb-0">Course</v-card-title>
        <div class="pa-5">
          <v-tabs
            v-model="selectedToggle"
            color="primary"
            align-tabs="center"
            grow
          >
            <v-tab href="#course">Course</v-tab>
            <v-tab href="#scorm">Scorm</v-tab>
          </v-tabs>

          <v-tabs-items v-model="selectedToggle">
            <v-tab-item value="course">
              <v-container fluid>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" class="pa-0">
                      <v-text-field
                        label="Description"
                        outlined
                        :rules="[(v) => !!v || 'Description is required']"
                        prepend-icon="mdi-text"
                        v-model="legacyCourse.description"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <v-text-field
                        label="Course name on certificate"
                        outlined
                        :rules="[
                          (v) =>
                            !!v || 'Course name on certificate is required',
                        ]"
                        prepend-icon="mdi-text"
                        v-model="legacyCourse.course_name_on_certificate"
                        required
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        type="number"
                        class="pr-1"
                        v-model="legacyCourse.max_course_try"
                        label="Max Course Try"
                        prepend-icon="mdi-numeric"
                        outlined
                      />
                      <v-text-field
                        type="number"
                        hide-details
                        v-model="legacyCourse.passing_score"
                        label="Passing Score"
                        append-icon="mdi-percent"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <v-text-field
                        v-model="legacyCourse.sign_up_title"
                        prepend-icon="mdi-text"
                        label="Signup Title"
                        outlined
                      />
                    </v-col>
                    <div class="d-flex w-full mb-8 pb-2 pr-1">
                      <v-icon class="pr-2 html-icon">mdi-text</v-icon>
                      <div class="w-full pl-1">
                        <div class="mb-3 label grey--text">Signup Info</div>

                        <HtmlEditor
                          v-model="legacyCourse.sign_up_info"
                          className="w-100 mt-2"
                        ></HtmlEditor>
                      </div>
                    </div>
                    <v-col cols="12" class="pa-0">
                      <v-text-field
                        type="password"
                        v-model="legacyCourse.sign_up_password"
                        label="Signup Password"
                        outlined
                        required
                        prepend-icon="mdi-form-textbox-password"
                        :rules="[(v) => !!v || 'Signup password is required']"
                      />
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <v-textarea
                        outlined
                        required
                        no-resize
                        :rules="[
                          (v) => !!v || 'Extra signup check is required',
                        ]"
                        label="Extra Signup Check"
                        prepend-icon="mdi-text"
                        v-model="legacyCourse.extra_sign_up_check"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        type="number"
                        v-model="legacyCourse.max_video_answer_length"
                        label="Max Video Answer Length"
                        prepend-icon="mdi-numeric"
                        outlined
                      />
                    </v-col>
                    <div class="d-flex w-full mb-8 pb-2 pr-1">
                      <v-icon class="pr-2 html-icon">mdi-text</v-icon>
                      <div class="w-full pl-1">
                        <div class="mb-3 label grey--text">Terms of Use</div>

                        <HtmlEditor
                          v-model="legacyCourse.terms_of_use"
                          className="w-100 mt-2"
                        ></HtmlEditor>
                      </div>
                    </div>
                    <div class="d-flex w-full mb-8 pb-2 pr-1">
                      <v-icon class="pr-2 html-icon">mdi-text</v-icon>
                      <div class="w-full pl-1">
                        <div class="mb-3 label grey--text">Privacy Policy</div>

                        <HtmlEditor
                          v-model="legacyCourse.privacy_policy"
                          className="w-100 mt-2"
                        ></HtmlEditor>
                      </div>
                    </div>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-select
                        outlined
                        label="Language"
                        prepend-icon="mdi-format-list-bulleted-square"
                        v-model="legacyCourse.language"
                        :items="languages"
                        item-title="text"
                        item-value="value"
                        required
                        :rules="[(v) => !!v || 'Language is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        v-model="legacyCourse.url"
                        label="URL"
                        prepend-icon="mdi-text"
                        outlined
                        required
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        type="number"
                        :min="0"
                        v-model="legacyCourse.price_per_student"
                        label="Price per student"
                        prepend-icon="mdi-cash"
                        prepend-inner-icon="mdi-currency-eur"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        type="number"
                        class="pr-1"
                        v-model="legacyCourse.vat"
                        label="Vat"
                        min="0"
                        prepend-icon="mdi-percent"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <image-upload
                        class="pa-0"
                        label="Sign Up Logo"
                        v-model="legacyCourse.sign_up_logo"
                      ></image-upload>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <image-upload
                        class="pa-0"
                        label="Thumbnail"
                        :rules="[(v) => !!v || 'This is required']"
                        v-model="legacyCourse.thumbnail"
                      ></image-upload>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      <image-upload
                        class="pa-0"
                        label="Background Image"
                        :rules="[(v) => !!v || 'This is required']"
                        v-model="legacyCourse.background_image"
                      ></image-upload>
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        v-if="legacyCourse.certificate_template"
                        disabled
                        class="pa-0"
                        outlined
                        label="Certificate Template"
                        :value="legacyCourse.certificate_template"
                      >
                      </v-text-field>
                      <v-btn
                        v-if="legacyCourse.certificate_template"
                        color="primary"
                        class="ma-2"
                        @click="legacyCourse.certificate_template = null"
                      >
                        Change
                      </v-btn>
                      <v-select
                        outlined
                        label="Certificate"
                        v-model="legacyCourse.certificate_id"
                        :items="getCertificates"
                        prepend-icon="mdi-format-list-bulleted-square"
                        item-text="name"
                        item-value="id"
                        :rules="[(v) => !!v || 'This is required']"
                      ></v-select>
                    </v-col>
                    <div class="d-flex w-full mb-8 pb-2 pr-1">
                      <v-icon class="pr-2 html-icon">mdi-text</v-icon>
                      <div class="w-full pl-1">
                        <div class="mb-3 label grey--text">
                          Certificate Email
                        </div>

                        <HtmlEditor
                          v-model="legacyCourse.certificate_email"
                          className="w-100 mt-2"
                        ></HtmlEditor>
                      </div>
                    </div>
                    <div class="d-flex w-full mb-8 pb-2 pr-1">
                      <v-icon class="pr-2 html-icon">mdi-text</v-icon>
                      <div class="w-full pl-1">
                        <div class="mb-3 label grey--text">
                          Payment Invoice Mail Body
                        </div>

                        <HtmlEditor
                          v-model="legacyCourse.payment_invoice_mail_body"
                          className="w-100 mt-2"
                        ></HtmlEditor>
                      </div>
                    </div>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        prepend-icon="mdi-text"
                        v-model="legacyCourse.certificate_email_bcc"
                        label="Certificate Email BCC"
                        outlined
                      />
                    </v-col>
                    <v-col md="12" class="d-flex pa-0">
                      <v-select
                        outlined
                        label="Certificate Expire Year"
                        v-model="legacyCourse.certificate_expire_year"
                        :items="expireYears"
                        item-title="text"
                        item-value="value"
                        prepend-icon="mdi-format-list-bulleted-square"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        prepend-icon="mdi-text"
                        v-model="legacyCourse.sign_up_mail_subject"
                        label="Signup Mail Subject"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-select
                        outlined
                        label="Signup Notification"
                        :items="notifications"
                        item-text="label"
                        item-value="value"
                        prepend-icon="mdi-format-list-bulleted-square"
                        v-model="legacyCourse.notification_id"
                        :rules="[(v) => !!v || 'This is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="d-flex pa-0">
                      <v-text-field
                        prepend-icon="mdi-text"
                        v-model="legacyCourse.export_email"
                        label="Export Email"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="column in switches"
                      :key="column.db"
                      cols="12"
                      md="6"
                    >
                      <v-switch
                        class="pa-0 ma-0"
                        inset
                        v-model="legacyCourse[column.db]"
                        :label="column.text"
                        :true-value="1"
                        :false-value="0"
                        color="primary"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="color in colorPickers"
                      :key="color.db"
                      md="6"
                      class="d-flex justify-center pa-0"
                    >
                      <div class="ma-1">
                        <p class="color-picker-label">
                          {{ color.text }}
                        </p>
                        <v-color-picker
                          @input="setColor(color.db, $event)"
                          elevation="0"
                          mode="hexa"
                        ></v-color-picker>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pt-10" justify="end">
                    <v-btn
                      v-if="selectedToggle == 'course'"
                      color="primary"
                      @click="create"
                    >
                      Create Course
                    </v-btn>
                  </v-row>
                </v-form>
              </v-container>
            </v-tab-item>
            <v-tab-item value="scorm">
              <div class="pt-3 ma-5">
                <div class="d-flex mb-8 pb-2">
                  <v-icon class="pr-2 html-icon">mdi-file-upload</v-icon>
                  <div class="w-full pl-1">
                    <div class="label grey--text">Upload Scorm File</div>
                    <vue-dropzone
                      class="mt-5"
                      ref="myVueDropzone"
                      id="scormDropzone"
                      :options="dropzoneOptions"
                      v-on:vdropzone-complete="redirect()"
                    ></vue-dropzone>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import HtmlEditor from "@/components/HtmlEditor";
import ImageUpload from "@/components/ImageUpload.vue";
import { CERTIFICATE_EXPIRE_YEARS, LANGUAGES } from "@/shared/constants";
import { convertObjectToFormData } from "@/shared/utils";
import store from "@/store";
import _ from "lodash";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ImageUpload, vueDropzone: vue2Dropzone, HtmlEditor },
  metaInfo: {
    title: "Courses",
  },
  data() {
    return {
      expireYears: CERTIFICATE_EXPIRE_YEARS,
      selectedToggle: "course",
      currentFile: null,
      logoFile: null,
      signUpLogo: null,
      thumbnailFile: null,
      backgroundImageFile: null,
      languages: LANGUAGES,
      colorPickers: [
        { text: "Background Color Hex", db: "background_color_hex" },
        { text: "Button Color Hex", db: "button_color_hex" },
        { text: "Menu Background Color Hex", db: "menu_background_color_hex" },
        { text: "Menu Font Color Hex", db: "menu_font_color_hex" },
        { text: "Main Text Color Hex", db: "main_text_color_hex" },
        {
          text: "Mobile Menu Background Color Hex",
          db: "mobile_menu_background_color_hex",
        },
      ],
      switches: [
        { text: "Active", db: "active" },
        { text: "Active Login", db: "active_login" },
        { text: "Active Register", db: "active_register" },
        { text: "Has student ID", db: "has_student_id" },
        { text: "Has download", db: "has_download" },
        { text: "Has register after test", db: "has_register_after_test" },
        { text: "Payment", db: "payment" },
        { text: "Random Question", db: "random_question" },
        { text: "Show function name", db: "show_function_name" },
        { text: "Show Birthdate", db: "show_birthdate" },
        {
          text: "Display franchise partner name",
          db: "display_franchise_partner_name",
        },
      ],
      legacyCourse: {
        certificate_expire_year: 1,
        language: "NL",
        active: 1,
        active_login: 1,
        active_register: 1,
        payment: 1,
        random_questions: 0,
        show_function_name: 0,
        show_birthdate: 0,
        display_franchise_partner_name: 0,
        has_student_id: 0,
        has_download: 0,
        has_register_after_test: 0,
        vat: "0",
        price_per_student: "0.00",
        notification_id: null,
      },
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/scorm/file-upload`,
        headers: { Authorization: `Bearer ${store.getters.getToken}` },
        uploadMultiple: false,
        chunking: true,
        chunkSize: 5242880,
      },
    };
  },

  async mounted() {
    this.getNotifications.length
      ? this.getNotifications
      : await this.setNotifications();
  },

  computed: {
    ...mapGetters(["getCertificates", "getNotifications", "getDomain"]),

    notifications() {
      const selections = [];

      this.getNotifications.forEach((item) => {
        selections.push({
          label: item.key,
          value: item.id,
        });
      });

      return selections;
    },
  },

  methods: {
    ...mapActions([
      "uploadScormFile",
      "createCourse",
      "showSnackbar",
      "setNotifications",
    ]),

    selectFile(file) {
      this.currentFile = file;
    },

    setColor(object, value) {
      _.set(this.legacyCourse, object, value.hex);
    },

    async create() {
      if (
        !this.$refs.form.validate() ||
        (!this.legacyCourse.background_image &&
          this.selectedToggle === "course")
      ) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");

        return;
      }
      let formData = convertObjectToFormData(this.legacyCourse);

      if (this.legacyCourse.background_image) {
        formData.set("background_image", this.legacyCourse.background_image);
      } else {
        this.$store.dispatch("showSnackbar", "No background image given");
        return;
      }

      if (this.legacyCourse.sign_up_logo) {
        formData.set("sign_up_logo", this.legacyCourse.sign_up_logo);
      }

      if (this.legacyCourse.thumbnail) {
        formData.set("sign_up_logo", this.legacyCourse.thumbnail);
      }

      const course = await this.createCourse(formData);

      if (course) {
        await this.showSnackbar("Course upload success");
        this.redirect();
        this.legacyCourse = {};
      }
    },

    redirect() {
      this.showSnackbar("Course upload success");
      this.$store.dispatch("setCourseLookup");
      this.$router.push({ name: "course-view" });
    },
  },
};
</script>
<style>
.color-picker-label {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0 !important;
}

label {
  overflow: inherit !important;
}

.label {
  font-size: 16px;
  padding-left: 8px;
}

#scormDropzone {
  padding: 0;
  margin: 10px auto;
  min-height: 0;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.2);
  border-width: thin;

  .dz-preview {
    height: 150px;
  }

  .dz-details {
    border-radius: 4px;
  }

  .dz-progress {
    margin-top: 25px !important;
  }
}
</style>
